import React, { FC, useRef, useState } from "react";

export interface InputFileProps {
  label?: string;
  subLabel?: string;
  className?: string;
  name: string;
  accept?: string;
  multiple?: boolean;
  onChange?: (files: FileList | null) => void;
}

const InputFile: FC<InputFileProps> = ({
  label = "",
  subLabel = "",
  className = "",
  name,
  accept = "",
  multiple = false,
  onChange,
}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const filesArray = Array.from(files);
      setSelectedFiles(filesArray);
      if (onChange) {
        onChange(files);
      }
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const formatFileSize = (size: number) => {
    const kb = size / 1024;
    if (kb < 1024) {
      return `${kb.toFixed(2)} KB`;
    } else {
      return `${(kb / 1024).toFixed(2)} MB`;
    }
  };

  return (
    <div className={`flex gap-x-2 ${className}`}>
      {label && (
        <label htmlFor={name} className="mb-2 text-neutral-900 dark:text-neutral-100">
          {label}
        </label>
      )}
      <div className="relative flex items-center">
        <button
          type="button"
          className="px-4 py-2 bg-primary-500 text-white rounded-lg cursor-pointer hover:bg-primary-600 focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
          onClick={handleButtonClick}
        >
          Choose File
        </button>
        <input
          ref={fileInputRef}
          id={name}
          name={name}
          type="file"
          className="hidden"
          accept={accept}
          multiple={multiple}
          onChange={handleFileChange}
        />
      </div>
      <div className="mt-2">
        {selectedFiles.length > 0 ? (
          <ul className="space-y-1">
            {selectedFiles.map((file, index) => (
              <li key={index} className="text-neutral-700 dark:text-neutral-200">
                <span className="font-medium">{file.name}</span> -{" "}
                <span className="text-sm text-neutral-500">{formatFileSize(file.size)}</span>
              </li>
            ))}
          </ul>
        ) : (
          <span className="ml-4 text-neutral-500 dark:text-neutral-400">
            {multiple ? "No files selected" : "No file selected"}
          </span>
        )}
      </div>
      {subLabel && (
        <p className="mt-1 text-neutral-500 dark:text-neutral-400 text-sm font-light">
          {subLabel}
        </p>
      )}
    </div>
  );
};

export default InputFile;
