import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // This defaults to localStorage for web
import authReducer from './reducers/AuthSlice/AuthSlice';
import userReducer from './reducers/UserSlice/UserSlice'
import propertiesReducer from './reducers/Properties/PropertiesSlice' // Adjust the path to your user slice

const persistConfig = {
key: "root",
  storage,
  whitelist: ["auth"], // List the reducers you want to persist
};

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  property: propertiesReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const persistor = persistStore(store);
