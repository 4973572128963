import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import API from "../../../utils/helpers/APIHelper";
import { RootState } from "../../store";
// import { SignInRequestPayload, SignUpRequestPayload } from "./AuthSliceTypes";
import ErrorHandler from "../../../utils/helpers/ErrorHandler";
import { ENDPOINTS } from "../../../API/apiConstants";
import {
  UpdateUserRequestBody,
  UserResponseBody,
  UserState,
} from "./UserSliceType";

const initialState: UserState = {
  currentUser: null,
  loading: false,
  authenticated: false,
};

export const getCurrentUser = createAsyncThunk<
  any, // Payload type for successful response (you may want to type this more strictly)
  void,
  { state: RootState; rejectValue: any }
>("user/currentUser", async (_, { rejectWithValue }) => {
  try {
    const response = await API.get(ENDPOINTS.LOGGGED_IN);
    return response; // Returning data directly, which contains user info
  } catch (error) {
    ErrorHandler(error);
    return rejectWithValue(error);
  }
});

export const updateUserAPI = createAsyncThunk<
  UserResponseBody, // Payload type for successful response
  UpdateUserRequestBody,
  { state: RootState; rejectValue: any }
>("auth/updateUser", async (payload, { rejectWithValue }) => {
  try {
    const response = await API.patch(ENDPOINTS.UPDATE_USER, payload);
    // Ensure the correct shape of the response is returned
    return response.data as UserResponseBody;
  } catch (error) {
    ErrorHandler(error);
    return rejectWithValue(error);
  }
});

export const updateUserWithImageAPI = createAsyncThunk(
  "user/updateWithImage",
  async (formData: FormData, { rejectWithValue }) => {
    try {
      const response = await API.patch(ENDPOINTS.UPDATE_USER, formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateUserEmailAsVerified = createAsyncThunk<
  any, // Payload type for successful response
  void,
  { state: RootState; rejectValue: any }
>("user/updateUserEmailAsVerified", async (_, { rejectWithValue }) => {
  try {
    const response = await API.patch(ENDPOINTS.UPDATE_USER_EMAIL_AS_VERFIED);
    // Ensure the correct shape of the response is returned
    return response;
  } catch (error) {
    ErrorHandler(error);
    return rejectWithValue(error);
  }
});

export const deleteUserAccount = createAsyncThunk<
any, // Payload type for successful response
any,
{ state: RootState; rejectValue: any }
>(
  "user/deleteUserAccount",
  async (id, { rejectWithValue }) => {
    try {
      const response = await API.delete(`${ENDPOINTS.DELETE_USER}/${id}`);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getCurrentUser.fulfilled,
      (state, action: PayloadAction<UserResponseBody>) => {
        const userData = action?.payload?.data;
        state.loading = false;
        state.currentUser = {
          ...userData,
          email: userData.email || "example@mail.com",
          first_name: userData.first_name || "Unknown",
          last_name: userData.last_name || "Unknown",
          date_of_birth: userData.date_of_birth || "",
          phone: userData.phone || "",
          gender: userData.gender || "other",
          is_student: userData.is_student ?? false,
          is_host: userData.is_host ?? false,
        };
        state.authenticated = true;
      }
    );
    builder.addCase(updateUserAPI.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateUserEmailAsVerified.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteUserAccount.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addMatcher(
      (action) =>
        [
          getCurrentUser.pending.type,
          updateUserAPI.pending.type,
          updateUserEmailAsVerified.pending.type,
          deleteUserAccount.pending.type,
        ].includes(action.type),
      (state) => {
        state.loading = true;
      }
    );
    builder.addMatcher(
      (action) =>
        [
          getCurrentUser.rejected.type,
          updateUserAPI.rejected.type,
          updateUserEmailAsVerified.rejected.type,
          deleteUserAccount.rejected.type,
        ].includes(action.type),
      (state) => {
        state.loading = false;
      }
    );
  },
});

export const {} = userSlice.actions;

export default userSlice.reducer;
