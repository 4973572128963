import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import CommonLayout from "./CommonLayout";
import { AppDispatch, RootState } from "redux/store";
import __countryListing from "../../data/jsons/__countryListing.json";
import { Alert } from "../../shared/Alert/Alert";
import Details from "./Details";
import SocialMediaLink from "./SocialMediaLink";
import PaymentInfo from "./PaymentInfo";
import Educations from "./Educations";
import Notifications from "./Notifications";
import DeleteAccount from "./DeleteAccount";
import AccountInfo from "./AccountInfo";
import {
  getCurrentUser,
  updateUserEmailAsVerified,
} from "../../redux/reducers/UserSlice/UserSlice";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import successHandler from "../../utils/helpers/SuccessHandler";
import { toast } from "react-toastify";

const AccountPage = ({ className = "" }) => {
  const { currentUser, loading } = useSelector(
    (state: RootState) => state.user
  );
  const [localCount, setLocalCount] = useState(0);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (
      currentUser &&
      currentUser?.email_verified &&
      currentUser?.sign_in_count === 1 &&
      localCount === 0
    ) {
      dispatch(updateUserEmailAsVerified())
        .unwrap()
        .then((res) => {
          if (res) {
            toast.dismiss();
            successHandler("Email verified successfully!");
            dispatch(getCurrentUser());
            setLocalCount(1);
          }
        })
        .catch((error: any) => {
          console.error("Error verifying email: ", error);
        });
    }
  }, [currentUser]);

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Account || Booking React Template</title>
      </Helmet>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          <div>
            {currentUser && (
              <>
                {!currentUser.email_verified && (
                  <Alert type="info">
                    Info! Please verify your email via the link sent to your
                    inbox to access other pages.
                  </Alert>
                )}

                {currentUser.is_host &&
                  !currentUser.host_verified &&
                  currentUser.email_verified && (
                    <Alert type="info">
                      Info! Once the admin approves your profile, you can list
                      your properties.
                    </Alert>
                  )}
              </>
            )}
          </div>
          <h2 className="text-3xl font-semibold mt-6">
            Account Information ({currentUser?.is_student ? "Student" : "Host"})
          </h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 mt-6 mb-6"></div>
        </div>
        {currentUser?.is_host && <AccountInfo />}
        {currentUser?.is_student && (
          <>
            <Details />
            <SocialMediaLink />
            <PaymentInfo />
            <Educations />
            <Notifications />
            <DeleteAccount />
          </>
        )}
      </CommonLayout>
    </div>
  );
};

export default AccountPage;
