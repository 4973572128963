import React from "react";
import Avatar from "shared/Avatar/Avatar";
import ButtonPrimary from "shared/Button/ButtonPrimary";

const PaymentInfo = () => {
  return (
    <>
      <div className="flex flex-col md:flex-row">
        <div className="flex-shrink-0 flex items-start">
          <div className="relative rounded-full overflow-hidden flex">
            <Avatar
              sizeClass="w-32 h-32"
              containerClassName="hidden md:block invisible"
            />
          </div>
        </div>
        <div className="flex-grow mt-10 md:mt-0 md:ml-8 space-y-6">
          <h2 className="text-3xl font-semibold mt-6 mb-4">Payment Info</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 mt-6 mb-6"></div>
          <div className="">
            <h2 className="text-lg font-semibold mb-2">Payment</h2>
            <p className="text-sm text-gray-600 mb-4">Payment</p>
            <div className="flex justify-end">
              <ButtonPrimary
                type="submit"
                // disabled={!currentUser?.email_verified}
                className="mt-5"
                // loading={loading}
              >
                Save
              </ButtonPrimary>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentInfo;
