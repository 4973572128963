import React from "react";
import Input from "shared/Input/Input";

interface FormFieldProps {
  label: string;
  name: string;
  type?: "text" | "email" | "date" | "checkbox" | "radio" | "select" | "textarea" | "password";
  value: any; // Adjust value type for checkbox and radio
  onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
  error?: string | undefined | false;
  options?: Array<{ value: string; label: string }>; // For select and radio inputs
  rowIntextArea?: number;
  disabled?: boolean;
  placeholder?: string;
}

const FormField: React.FC<FormFieldProps> = ({
  label='',
  name,
  type = "text",
  value,
  placeholder='',
  onChange,
  onBlur,
  error,
  options=[],
  rowIntextArea = 3,
  disabled = false
  
}) => {
  return (
    <div>
      <label className="block mb-1">{label}:</label>
      {type === "select" ? (
        <select
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          className="py-3 rounded-2xl"
        >
          <option value='' disabled>Select</option>
          {options?.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      ) : type === "checkbox" ? (
        <Input
          type="checkbox"
          name={name}
          disabled={disabled}
          checked={value as boolean}
          onChange={onChange}
          onBlur={onBlur}
        />
      ) : type === "radio" ? (
        options?.map(option => (
          <label key={option.value}>
            <Input
              type="radio"
              name={name}
              disabled={disabled}
              value={option.value}
              checked={value === option.value}
              onChange={onChange}
              onBlur={onBlur}
            />
            {option.label}
          </label>
        ))
      ) : type === "textarea" ? (
        <textarea
          name={name}
          disabled={disabled}
          value={value as string}
          onChange={onChange}
          onBlur={onBlur}
          rows={rowIntextArea}
          placeholder={placeholder}
          className="border p-2 rounded-2xl w-full" // You can adjust the styles as needed
        />
      ) : type === "password" ? (
        <Input
          type="password" // Set type to password
          disabled={disabled}
          name={name}
          value={value as string}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
          className="border p-2 rounded w-full" // Add your custom styles
        />

      ) : (
        <Input
          type={type}
          disabled={disabled}
          name={name}
          value={value as string}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
        />
      )}
      {error && <div className="text-red-600 text-sm mt-1">{error}</div>}
    </div>
  );
};

export default FormField;
