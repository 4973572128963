import React from "react";
import facebookSvg from "../../images/Facebook.svg";
import googleSvg from "images/Google.svg";
import { useGoogleLogin } from "@react-oauth/google";
import { useDispatch } from "react-redux";
import { AppDispatch } from "redux/store";
import { googleSignIn } from "../../redux/reducers/AuthSlice/AuthSlice";
import { getCurrentUser } from "../../redux/reducers/UserSlice/UserSlice";
import { useNavigate } from "react-router-dom";
import successHandler from "../../utils/helpers/SuccessHandler";
import FacebookLogin from "react-facebook-login";

function SocialAuth() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const handleSocialLogin = (accessToken: string, type: string) => {
    const payload = {
      token: accessToken,
      type,
    };

    dispatch(googleSignIn(payload))
      .unwrap()
      .then((response) => {
        if (response?.success) {
          localStorage.setItem("authToken", response?.access_token);
          successHandler(response?.message || "Logged in successfully");
          dispatch(getCurrentUser())
            .unwrap()
            .then((res) => {
              if (res?.success) {
                if (res?.data?.email_verified) {
                  navigate("/");
                } else {
                  navigate("/account");
                }
              }
            });
        } else {
          console.log(`${type} login failed: `, response);
        }
      })
      .catch((error) => {
        console.log(`Error in ${type} login: `, error);
      });
  };

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      handleSocialLogin(tokenResponse.access_token, "google");
    },
  });

  const fbLogin = (response: any) => {
    if (response?.accessToken) {
      handleSocialLogin(response.accessToken, "facebook");
    } else {
      console.log("Facebook login failed: ", response);
    }
  };

  return (
    <div>
      {" "}
      <div className="grid gap-3">
        <button
          className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
          onClick={() => googleLogin()} // Attach the login function to onClick
        >
          <img
            className="flex-shrink-0"
            src={googleSvg}
            alt={"Continue with Google"}
          />
          <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
            Continue with Google
          </h3>
        </button>
        <button
          className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px] flex justify-center"
          //   onClick={() => googleLogin()} // Attach the login function to onClick
        >
          <img
            className="flex-shrink-0"
            src={facebookSvg}
            alt={"Continue with Facebook"}
          />
          <div className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
            <FacebookLogin
              appId={process.env.REACT_APP_FACEBOOK_APP_ID || ""}
              autoLoad={false}
              fields="name,email,picture"
              callback={fbLogin}
              cssClass="my-facebook-button-class text-sm"
              textButton="Continue with Facebook"
            />
          </div>
        </button>
      </div>
    </div>
  );
}

export default SocialAuth;
