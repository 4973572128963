import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import API from "../../../utils/helpers/APIHelper";
import { RootState } from "../../store";
import {
  SignInRequestPayload,
  SignUpRequestPayload,
  socialSignUpPayload,
} from "./AuthSliceTypes";
import ErrorHandler from "../../../utils/helpers/ErrorHandler";
import { AxiosError } from "axios"; // Import AxiosError to use for type assertion
import InfoHandler from "../../../utils/helpers/InfoHandler";
import { ENDPOINTS } from "../../../API/apiConstants";

export interface AuthState {
  user: {
    email: string | null;
    firstName: string | null;
    lastName: string | null;
    dateOfBirth: string | null;
    phone: string | null;
    gender: string | null;
    is_student: boolean | null;
    is_host: boolean | null;
  } | null;
  accessToken: string | null;
  authenticated: boolean;
  loading: boolean;
  error: string | null;
}

const initialState: AuthState = {
  user: null,
  accessToken: localStorage.getItem("authToken"),
  authenticated: !!localStorage.getItem("authToken"),
  loading: false,
  error: null,
};

export const signIn = createAsyncThunk<
  any,
  SignInRequestPayload,
  { state: RootState; rejectValue: any }
>("auth/signIn", async (payload, { rejectWithValue }) => {
  try {
    const response = await API.post(ENDPOINTS.SIGN_IN, payload);
    return response;
  } catch (error: any) {
    if (error instanceof AxiosError && error.response) {
      ErrorHandler(error); // Pass the complete AxiosError object to ErrorHandler
      return rejectWithValue({
        message:
          error.response.data?.error_description?.[0] ||
          error.response.data?.message ||
          "Login failed",
      });
    }
    return rejectWithValue({ message: "An unknown error occurred" });
  }
});

export const signUp = createAsyncThunk<
  any,
  SignUpRequestPayload,
  { state: RootState; rejectValue: any }
>("auth/signUp", async (payload, { rejectWithValue }) => {
  try {
    const response = await API.post(ENDPOINTS.SIGN_UP, payload);
    return response;
  } catch (error: any) {
    if (error instanceof AxiosError && error.response) {
      ErrorHandler(error); // Pass the complete AxiosError object to ErrorHandler
      return rejectWithValue({
        message:
          error.response.data?.error_description?.[0] ||
          error.response.data?.message ||
          "Signup failed",
      });
    }
    return rejectWithValue({ message: "An unknown error occurred" });
  }
});

export const googleSignIn = createAsyncThunk<
  any,
  socialSignUpPayload,
  { state: RootState; rejectValue: any }
>("auth/googleSignIn", async (payload, { rejectWithValue }) => {
  try {
    const response = await API.post(ENDPOINTS.SOCIAL_SIGNING, payload);
    return response;
  } catch (error: any) {
    if (error instanceof AxiosError && error.response) {
      ErrorHandler(error);
      return rejectWithValue({
        message:
          error.response.data?.error_description?.[0] ||
          error.response.data?.message ||
          "Google login failed",
      });
    }
    return rejectWithValue({ message: "An unknown error occurred" });
  }
});

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      state.accessToken = null;
      state.authenticated = false;
      state.error = null; // Clear error on logout
      localStorage.removeItem("authToken");
      localStorage.clear();
      InfoHandler("You have been logged out");
      // const persistor = persistStore(store);
      // persistor.purge();
      // setTimeout(()=> {
      //   window.location.href="/"
      //   window.location.reload()
      // }, 2000)
    },
  },
  extraReducers: (builder) => {
    // Handle Sign In
    builder.addCase(signIn.fulfilled, (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.user = {
        email: action.payload.data?.email,
        firstName: action.payload.data?.first_name,
        lastName: action.payload.data?.last_name,
        dateOfBirth: action.payload.data?.date_of_birth,
        phone: action.payload.data?.phone,
        gender: action.payload.data?.gender,
        is_student: action.payload.data?.is_student || null,
        is_host: action.payload.data?.is_host || null,
      };
      state.accessToken = action.payload.access_token;
      localStorage.setItem("authToken", action.payload.access_token);
      state.authenticated = true;
      state.error = null; // Clear error on success
    });

    // Handle Sign Up
    builder.addCase(signUp.fulfilled, (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.user = {
        email: action.payload?.data?.email,
        firstName: action.payload?.data?.first_name,
        lastName: action.payload?.data?.last_name,
        dateOfBirth: action.payload?.data?.date_of_birth,
        phone: action.payload?.data?.phone,
        gender: action.payload?.data?.gender,
        is_student: action.payload.data?.is_student || null,
        is_host: action.payload.data?.is_host || null,
      };
      state.accessToken = action.payload?.access_token;
      localStorage.setItem("authToken", action.payload?.access_token);
      state.authenticated = true;
      state.error = null; // Clear error on success
    });

    // Handle Google Sign In
    builder.addCase(
      googleSignIn.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.user = {
          email: action.payload.data?.email,
          firstName: action.payload.data?.first_name,
          lastName: action.payload.data?.last_name,
          dateOfBirth: action.payload.data?.date_of_birth,
          phone: action.payload.data?.phone,
          gender: action.payload.data?.gender,
          is_student: action.payload.data?.is_student,
          is_host: action.payload.data?.is_host,
        };
        state.accessToken = action.payload.access_token;
        localStorage.setItem("authToken", action.payload.access_token);
        state.authenticated = true;
        state.error = null; // Clear error on success
      }
    );

    // Handle pending states
    builder.addMatcher(
      (action) =>
        [
          signIn.pending.type,
          signUp.pending.type,
          googleSignIn.pending.type,
        ].includes(action.type),
      (state) => {
        state.loading = true;
        state.error = null; // Clear error on new request
      }
    );

    // Handle rejected states
    builder.addMatcher(
      (action) =>
        [
          signIn.rejected.type,
          signUp.rejected.type,
          googleSignIn.rejected.type,
        ].includes(action.type),
      (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.error = action.payload; // Set error message from rejected action
        ErrorHandler({ name: "AxiosError", message: action.payload }); // Display error in toast
      }
    );
  },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
