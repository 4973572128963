import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import API from "../../../utils/helpers/APIHelper";
import { RootState } from "../../store";
// import { SignInRequestPayload, SignUpRequestPayload } from "./AuthSliceTypes";
import ErrorHandler from "../../../utils/helpers/ErrorHandler";
import { ENDPOINTS } from "../../../API/apiConstants";
import {
  GetAllPropertiesResponse,
  PropertiesState,
} from "./PropertiesSliceTypes";

const initialState: PropertiesState = {
  loading: false,
  allProperties: [],
};

export const getAllProperties = createAsyncThunk<
  any, // Payload type for successful response (you may want to type this more strictly)
  void,
  { state: RootState; rejectValue: any }
>("properties/allProperties", async (_, { rejectWithValue }) => {
  try {
    const response = await API.get(ENDPOINTS.GET_ALL_PROPERTIES);
    return response; // Returning data directly, which contains user info
  } catch (error) {
    ErrorHandler(error);
    return rejectWithValue(error);
  }
});

const propertiesSlice = createSlice({
  name: "properties",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getAllProperties.fulfilled,
      (state, action: PayloadAction<any>) => {
        const data = action?.payload;
        state.allProperties = data?.properties;
        state.loading = false;
      }
    );
    builder.addMatcher(
      (action) => [getAllProperties.pending.type].includes(action.type),
      (state) => {
        state.loading = true;
      }
    );
    builder.addMatcher(
      (action) => [getAllProperties.rejected.type].includes(action.type),
      (state) => {
        state.loading = false;
      }
    );
  },
});

export const {} = propertiesSlice.actions;

export default propertiesSlice.reducer;
