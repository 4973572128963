import * as Yup from "yup";

// validation schema from signup
export const SignUpSchema = Yup.object().shape({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  // username: Yup.string().required("Username is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  // phone: Yup.string()
  //   .matches(/^[0-9]{10}$/, "Phone number must be of 10 digits")
  //   .required("Phone number is required"),

  password: Yup.string()
    .min(6, "Password is too short")
    .required("Password is required"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), undefined], "Passwords must match")
    .required("Confirm password is required"),
  date_of_birth: Yup.date().required("Date of birth is required"),
});

// Validation schema for updating user information
export const UpdateUserSchema = Yup.object().shape({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  date_of_birth: Yup.date().required("Date of birth is required"),
  phone: Yup.string().required("Phone number is required"),
  about_me: Yup.string()
    .required("About me is required")
    .test("min-words", "Must be at least 50 words.", function (value) {
      const wordCount = value ? value.trim().split(/\s+/).length : 0;
      return wordCount >= 50;
    })
    .test("max-words", "Must not exceed 150 words.", function (value) {
      const wordCount = value ? value.trim().split(/\s+/).length : 0;
      return wordCount <= 150; // Check for at most 150 words
    }),
  live_in: Yup.string().required("Nationality is required"),
  gender: Yup.string().nullable(),
  is_student: Yup.boolean(),
  is_host: Yup.boolean(),
});

// Validation schema for Login
export const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .min(6, "Password is too short")
    .required("Password is required"),
});

// validation schema for update password
export const passwordvalidationSchema = Yup.object({
  new_Password: Yup.string()
    .min(8, "Password must be at least 8 characters long")
    .required("New password is required"),
  confirm_Password: Yup.string()
    .oneOf([Yup.ref("new_Password"), undefined], "Passwords must match")
    .required("Confirm password is required"),
});

//validation schema for personal details
export const personalDetailsSchema = Yup.object().shape({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  gender: Yup.string().nullable(),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  date_of_birth: Yup.date().required("Date of birth is required"),
  phone: Yup.string()
    // .matches(
    //   /^\+\d{1,3} \d{1,15}$/,
    //   "Phone # WhatsApp must be in the format +[country code] [number] (e.g., +33 232 3322)"
    // )
    .required("Phone # WhatsApp is required"),
  about_me: Yup.string()
    .required("About me is required")
    .test("min-words", "Must be at least 50 words.", function (value) {
      const wordCount = value ? value.trim().split(/\s+/).length : 0;
      return wordCount >= 50;
    })
    .test("max-words", "Must not exceed 150 words.", function (value) {
      const wordCount = value ? value.trim().split(/\s+/).length : 0;
      return wordCount <= 150; // Check for at most 150 words
    }),
  nationality: Yup.string().required("Nationality is required"),
  // home_university: Yup.string().when('tenant_type', {
  //   is: (val: string) => val === 'student',
  //   then: Yup.string().required("Home University is required"),
  //   otherwise: Yup.string().notRequired(),
  // }),
  // abroad_university: Yup.string().when('tenant_type', {
  //   is: (val: string) => val === 'student',
  //   then: Yup.string().required("Abroad University is required"),
  //   otherwise: Yup.string().notRequired(),
  // }),
  // education_major: Yup.string().when('tenant_type', {
  //   is: (val: string) => val === 'student',
  //   then: Yup.string().required("Education Major is required"),
  //   otherwise: Yup.string().notRequired(),
  // }),
  // company_name: Yup.string().when('tenant_type', {
  //   is: (val: string) => val === 'professional',
  //   then: Yup.string().required("Company Name is required"),
  //   otherwise: Yup.string().notRequired(),
  // }),


  live_in: Yup.string().required("Current Location required"),
  speak_language: Yup.string().required("Language is required"),
  tenant_type: Yup.string()
    .required("Tenant type is required")
    .test(
      "is-valid-tenant-type",
      'Tenant type must be either "student" or "professional"',
      (value) => {
        return ["student", "professional"].includes(value);
      }
    ),
});

// validation schema for social media links
const addProtocol = (url: string) => {
  if (url && !/^https?:\/\//i.test(url)) {
    return `https://${url}`;
  }
  return url;
};

export const socialMediaDetailsSchema = Yup.object().shape({
  facebook_url: Yup.string()
    .transform((value) => addProtocol(value))
    .url("Invalid Facebook URL")
    .nullable(),
  linkedin_url: Yup.string()
    .transform((value) => addProtocol(value))
    .url("Invalid LinkedIn URL")
    .nullable(),
  instagram_url: Yup.string()
    .transform((value) => addProtocol(value))
    .url("Invalid Instagram URL")
    .nullable(),
});

// validation schema for university acceptance
export const universityLOASchema = Yup.object({
  university_loa: Yup.mixed()
    .nullable()
    .test("fileType", "Unsupported File Format", (value) => {
      if (!value) return true;
      const file = value as File;
      return (
        ["application/pdf", "image/jpg"].includes(file.type) ||
        file.name.endsWith(".jpg")
      );
    })
    .test("fileSize", "File Size is too large", (value) => {
      if (!value) return true;
      const file = value as File;
      return file.size <= 5 * 1024 * 1024;
    }),

  work_loa: Yup.mixed()
    .nullable()
    .test("fileType", "Unsupported File Format", (value) => {
      if (!value) return true;
      const file = value as File;
      return (
        ["application/pdf", "image/jpg"].includes(file.type) ||
        file.name.endsWith(".jpg")
      );
    })
    .test("fileSize", "File Size is too large", (value) => {
      if (!value) return true;
      const file = value as File;
      return file.size <= 5 * 1024 * 1024;
    }),
});


